import React, { Component } from "react";
import { Add, AccountBalanceWalletOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./profile.css";
import "./forms.css";
import "./CSS/pay.css";

class Pay extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="dash_content2">
          <div className="title">Payment</div>{" "}
          <div className="profile_first">
            <div className="pay_container">
              <div className="pay_card">
                <div className="wallet_container ">
                  <div className="wallet">
                    <AccountBalanceWalletOutlined style={{ fontSize: 22 }} />
                  </div>
                  <div className="addtowallet" title="Add To Wallet">
                    <Add style={{ fontSize: 20 }} />
                  </div>
                </div>
                <div className="Amount">
                  <p className="currency">&#8358; </p>
                  <p> 5,000</p>
                </div>
                <div className="card_title">Wallet Balance</div>
                <div className="issuer">PWARE CARD</div>
              </div>
            </div>
            <br />
            <p className="payins">
              *Please re-enter your password to confirm payment*
            </p>
            <br />
            <div className="paypass">
              {" "}
              <input
                type="text"
                className="form_input"
                placeholder="...Enter pass here"
              />
            </div>
            <br />
            <p className="payerr">
              <i>...Insufficient Wallet Balance, Kindly Add to Wallet</i>
            </p>
            <p className="payerr">
              <i>...Incorrect password</i>
            </p>
            <br />
            <Link to="/Confirm">
              <div className="pay_submit">Confirm Payment</div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Pay;
