import React, { useState, useEffect } from "react";
import "../main.css";
import Foots from "../footer.jsx";
import Lheader from "./landhead.jsx";
import "./css/landingpage.css";
import axios from "axios";
import whatsappicon from "../images/whatapp.jpg";
import { Link } from "react-router-dom";
import ServicesView from "../ServiceView.jsx";
import Rep from "../images/Repairs.jpeg";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Slider from "../../components/Slider";
const Landingpage = () => {
  return (
    <React.Fragment>
      <Lheader />

      <div className="header_plus">
        <a href="https://wa.me/+23407038621038">
          <div className="w_redirect">
            <img src={whatsappicon} alt="Whatsapp Contact" />
          </div>
        </a>
        <Slider />
      </div>
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      <Foots />
    </React.Fragment>
  );
};

export default Landingpage;
