import React, { Component } from "react";
import twiter from "./images/twiter.jpg";
import linkedin from "./images/Linkedin.png";
import whatsapp from "./images/whatapp.jpg";
import instagram from "./images/instagram.png";
import logo from "./images/logo.png";
import { Link, useNavigate } from "react-router-dom";

import {
  Facebook,
  Instagram,
  Twitter,
  WhatsApp,
  LocationOn,
} from "@mui/icons-material";

class Foots extends Component {
  render() {
    return (
      <footer className="foot">
        <div className="foot_main">
          <div className="foot_content">
            <h3>
              Pyramidware... <br /> Absolutely the Best
            </h3>
            <p>We are here to serve you</p>
            <br />
            <p>
              <b>Location</b>
            </p>
            <div className="locate">
              <LocationOn style={{ fontSize: 19, marginRight: 10 }} />{" "}
              <p>33, Enugu Road, Agbani...</p>
            </div>
          </div>{" "}
          <ul className="foot_content2 plus">
            <li className="foot_title">Our Services</li>
            <li>Printing</li>
            <li>Graphics Design</li>
            <li>Computer Repairs </li>
            <li>Web Development</li>{" "}
          </ul>
          <ul className="foot_content2 plus">
            <li className="foot_title">Pyramidware</li>
            <li>About Us</li>
            <li>Terms and condition</li>
            <Link to="/Contact">
              <li>Contact us</li>{" "}
            </Link>
          </ul>
          <div className="foot_content3">
            <p className="newsletter"> Stay in Touch</p>
            <p className="newsletter_info">
              Sign up to recieve latest news and exclusive offers ever week{" "}
            </p>
            <form>
              <input
                type="email"
                id="n_email"
                className="email_c"
                name="email"
                placeholder="Pls enter your email..."
                required="required"
              />

              <input
                type="submit"
                value="Yes! Keep Me Updated"
                className="n_submit"
              />
            </form>
          </div>
        </div>

        <div className="additional_info">
          <div>Copyright @ 2015-2022 Pyramidware </div>
          <div className="icon_holder">
            <div className="foot_icon">
              <Facebook style={{ fontSize: 21 }} />
            </div>
            <div className="foot_icon">
              <WhatsApp style={{ fontSize: 21 }} />
            </div>
            <div className="foot_icon">
              <Instagram style={{ fontSize: 21 }} />
            </div>

            <div className="foot_icon">
              {" "}
              <Twitter style={{ fontSize: 21 }} />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Foots;
