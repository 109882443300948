import React, { Component } from "react";
import "../main.css";
import "./css/landingpage.css";

import Newlogo from "../images/logo.jpg";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";

class Lheader extends Component {
  state = {
    navbar: false,
    sub_navbar: true,
    click: true,
  };

  contact_click = () => {
    this.setState({ click: !this.state.click });
  };
  menu = () => {
    this.setState({ navbar: !this.state.navbar });
  };

  sub_menu = () => {
    this.setState({ sub_navbar: !this.state.sub_navbar });
  };

  authenticate = () => {};

  render() {
    let display = this.state.navbar ? "menu_display" : "";
    let display_two = this.state.sub_navbar ? "menu_show" : "";
    let click_drop = this.state.click ? "display" : "";
    return (
      <React.Fragment>
        <div className="header_container realign">
          <div className="toleft">
            <img className="mainlogo" src={Newlogo} />
            <Link to="Contact">
              {" "}
              <div className="contac">Contact us </div>{" "}
            </Link>{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Lheader;
