import React, { useState } from "react";
import { Link } from "react-router-dom";
import Graphic from "./graphics.png";
const Third = () => {
  return (
    <React.Fragment>
      <div className="third_cont">
        <div>
          <img src={Graphic} className="imggraph" />
        </div>{" "}
        <div className="text_cont2">
          <h1 className="graphics_d">Graphics </h1>
          <p className="bigText2">DESIGN</p>
          <p>Business card, flyers, posters, brochure,</p>
          <p> wedding cards e.t.c designs and printing</p>{" "}
          <div className="ph2">
            {" "}
            <Link to="/Soon">
              <p className="print_now">Get yours now</p>
            </Link>{" "}
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Third;
