import React, { Component, useDebugValue, useState, useEffect } from "react";
import axios from "axios";
import "./profile.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  PowerSettingsNewTwoTone,
  Work,
  ShoppingCart,
} from "@mui/icons-material";
import Newlogo from "./images/logo.jpg";
import { Avatar } from "@mui/material";
import Mine from "./images/default.jpg";
import {
  Logout,
  Dashboard,
  Person,
  ManageAccounts,
  Portrait,
  AccountBalanceWallet,
  Home,
} from "@mui/icons-material";
import Badge from "@mui/material/Badge";

//require('dotenv').config();

const Sidebar = (props) => {
  //console.log(props)
  const navigate = useNavigate();

  let siteUser = props.siteUser ? props.siteUser : { reg: "user", profile: {} };
  let _siteUser = { ...siteUser };
  let dat = { image: "default.jpg" };
  if (!siteUser.hasOwnProperty("profile")) {
    _siteUser = { ..._siteUser, profile: dat };
  }
  siteUser = _siteUser;
  /*if(props.siteUser){
    
    setSiteUser(props.siteUser);
  }*/ console.log(props.siteUser);
  const [side, setSide] = useState(false);
  const [exp, setExp] = useState(false);
  let extraMenu = null;

  const myHost = process.env.REACT_APP_SERVER_HOST || "http://localhost:5001";

  if (siteUser.reg.split("@").pop() === "admin") {
    extraMenu = (
      <span>
        <Link to="/request">
          <li className="profile_menu">
            <i className="iconpro">
              {" "}
              <Work style={{ fontSize: 21, paddingBottom: 0 }} />
            </i>
            <p>Manage Requests</p>
          </li>
        </Link>
        <Link to="/pageServices">
          <li className="profile_menu">
            <i className="iconpro">
              {" "}
              <Work style={{ fontSize: 21, paddingBottom: 0 }} />
            </i>
            <p>Manage Service</p>
          </li>
        </Link>
        <Link to="/manageProducts">
          <li className="profile_menu">
            <i className="iconpro">
              {" "}
              <Work style={{ fontSize: 21, paddingBottom: 0 }} />
            </i>
            <p>Manage Products</p>
          </li>
        </Link>
        <Link to="/manageUsers">
          <li className="profile_menu2">
            <i className="iconpro">
              {" "}
              <Work style={{ fontSize: 21, paddingBottom: 0 }} />
            </i>
            <p>Manage Users</p>
          </li>
        </Link>
      </span>
    );
  } else {
    extraMenu = "You are amazing " + siteUser.reg.split("@").pop();
  }

  const sideShow = () => {
    setSide(!side);
    setExp(!exp);
  };

  let drop = side ? "show_cont" : "";
  let incre = exp ? "width_incre" : "";

  return (
    <React.Fragment>
      {" "}
      <div className={" newside " + drop}>
        <div className="dash_cont">
          <div className="l_cont">
            {" "}
            <img className="mainlogo" src={Newlogo} />
          </div>

          <div className="pro">
            <ul>
              <Link to="/Entry">
                <li className="profile_menu">
                  <i className="iconpro">
                    {" "}
                    <Home style={{ fontSize: 21, paddingBottom: 0 }} />
                  </i>
                  <p>Home</p>
                </li>
              </Link>
              <Link to="/Dashboard">
                <li className="profile_menu">
                  <i className="iconpro">
                    {" "}
                    <Dashboard style={{ fontSize: 21, paddingBottom: 0 }} />
                  </i>
                  <p>Dashboard</p>
                </li>
              </Link>
              <Link to="/User">
                {" "}
                <li className="profile_menu">
                  <i className="iconpro">
                    {" "}
                    <Person style={{ fontSize: 21, paddingBottom: 0 }} />
                  </i>
                  <p> Profile</p>
                </li>
              </Link>

              <Link to="/Wallet">
                <li className="profile_menu">
                  <i className="iconpro">
                    {" "}
                    <AccountBalanceWallet
                      style={{ fontSize: 21, paddingBottom: 0 }}
                    />
                  </i>
                  <p> My Wallet</p>
                </li>
              </Link>

              <Link to="/Services">
                <li className="profile_menu">
                  <i className="iconpro">
                    {" "}
                    <Work style={{ fontSize: 21, paddingBottom: 0 }} />
                  </i>
                  <p>Online Service</p>
                </li>
              </Link>

              <Link to="/Cart">
                <li className="profile_menu2">
                  <i className="iconpro">
                    {" "}
                    <ShoppingCart style={{ fontSize: 21, paddingBottom: 0 }} />
                  </i>
                  <p> My Cart</p>
                </li>
              </Link>

              {extraMenu}
              <li className="profile_menu">
                <i className="iconpro">
                  {" "}
                  <PowerSettingsNewTwoTone
                    style={{ fontSize: 21, paddingBottom: 0 }}
                  />
                </i>
                <p
                  onClick={() => {
                    axios
                      .post(myHost + "/user/signout")
                      .then((res) => {
                        navigate("/login");
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  {" "}
                  Logout{" "}
                </p>
              </li>
            </ul>
          </div>
        </div>{" "}
      </div>
      <div className={incre + " main_dash "}>
        <div className="pro_head">
          <div
            className="pro_subhead"
            onClick={() => {
              sideShow();
            }}
          >
            <Menu style={{ fontSize: 23 }} />
          </div>
          <div className="pro_subhead2">
            <Link to="/Cart">
              {" "}
              <Badge badgeContent={2} color="primary">
                <ShoppingCart style={{ fontSize: 21, paddingBottom: 0 }} />
              </Badge>
            </Link>
            {siteUser.profile.image ? (
              <Avatar
                src={require("../../public/profilePics/" +
                  siteUser.profile.image)}
                alt=""
              />
            ) : (
              <Avatar alt="profile pic" src={Mine} />
            )}
          </div>
        </div>{" "}
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
