import React, { useState } from "react";
import { Link } from "react-router-dom";
import Newlogo from "../assets/logo.jpg";
import Large from "../assets/largeFormat.png";
const Second = () => {
  return (
    <React.Fragment>
      <div className="second_cont">
        <div className="text_cont">
          <h1 className="large_format">Large Format </h1>
          <p className="bigText">PRINTING</p>
          <p>
            Print your Banners, Sav stickers, labels, etc. for adverts and
            occassions{" "}
          </p>{" "}
          <div className="ph">
            {" "}
            <Link to="/Soon">
              {" "}
              <p className="print_now">Print Now</p>
            </Link>{" "}
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Second;
