import React from "react";

function SliderContent({ activeIndex, sliderImage }) {
 const check =()=>{console.log(activeIndex);}
  return (
    <section className="fill_cont">
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          {slide.Comp}

          
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
