import React, { Component } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Landingpage from "./component/landing/landingpage.jsx";
import Soon from "./component/landing/soon.jsx";

import Contact from './component/contact.jsx';

function New() {
   return(
    <Router>
      <Routes>
   <Route exact path="/" element={<Landingpage/>}/>    
    <Route exact path="/Soon" element={<Soon/>}/>    
     <Route exact path="/Contact" element={<Contact/>}/>    
    </Routes>

    </Router>
  );

}

export default New;