import React, { useState, useEffect } from "react";
import "./main.css";
import Foots from "./footer.jsx";
import Lheader from "./landing/landhead.jsx";
import {
  Facebook,
  Instagram,
  Twitter,
  WhatsApp,
  LocationOn,
  Call,
  WhatsappOutlined,
  FacebookOutlined,
} from "@mui/icons-material";
const Contact = () => {
  return (
    <React.Fragment>
      <Lheader />
      <div className="dash_content">
        <div className="profile_first">
          <div className="title">Contact Us</div>
          <br />
          <h4>OUR CONTACT INFO</h4>
          <br />
          <div className="locate">
            <LocationOn style={{ fontSize: 19, marginRight: 10 }} />{" "}
            <p>
              33, Enugu Road, Agbani, Nkanu West L.G.A Enugu State, Nigeria...
            </p>
          </div>
          <br />{" "}
          <div className="locate">
            <Call style={{ fontSize: 19, marginRight: 10 }} />{" "}
            <p>
              {" "}
              07038621038 <br />
              09075765253
            </p>
          </div>
          <br />
          <div className="locate">
            <WhatsappOutlined style={{ fontSize: 19, marginRight: 10 }} />{" "}
            <p> +234 703 862 1038</p>
          </div>
          <br />
          <div className="locate">
            <FacebookOutlined style={{ fontSize: 19, marginRight: 10 }} />{" "}
            <p>Pyramidware</p>
          </div>
          <br />
        </div>{" "}
      </div>{" "}
      <Foots />
    </React.Fragment>
  );
};

export default Contact;
