import React, { useState } from "react";
import Foots from "./footer.jsx";
import "./forms.css";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { DriveFolderUpload } from "@mui/icons-material";
import Sidebar from "./newsidebar.jsx";

const Gserve = () => {
  return <React.Fragment>Available soon</React.Fragment>;
};

export default Gserve;
