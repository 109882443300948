import React, { Component, useDebugValue, useState,useEffect } from "react";
import Session from 'react-session-api';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import Foots from "./footer.jsx";

import Header from "./header.jsx";

//require('dotenv').config();

axios.defaults.withCredentials = true;

const Login = ()=> {
  

  const [username, setUsername] = useState();
  const [password , setPassword]= useState();
  const [formMessage, setFormMessage] = useState();
  const [registered, setRegistered] = useState(false);
  const [formError, setFormError] = useState('');

    
  const myHost = process.env.REACT_APP_SERVER_HOST||'http://localhost:5001';

  let navigate = useNavigate();
  useEffect(()=>{
    const config = {
      headers:{
        Authorization:"here auth"
      }
    }
    //axios.post(myHost+'/user/isAuth',config)
    axios.post(myHost+'/user/isAuth')
    .then(res => {
      console.log(res);
      //console.log(res.data.message)
      setRegistered(res.data.isAuth)
      })
    .catch(err => console.log(err))
  
  },[])
  
  useEffect(()=>{
    if(registered){
      navigate('/dashboard')
    }
  },[registered])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormMessage("");

    if(formError === ''){
      
    const data = {
      username: username,
      password: password,
    };
    axios.post(myHost+'/user/login',data)
    .then(res => {
      console.log(res)
      console.log(res.data.message)
      setFormMessage(res.data.message)
      if(res.data.registered){
        //Session.onSet("loginSession");
        //Session.set("loginSession", res.data.session);
        navigate('/dashboard')
      }
    })
    .catch(err => {console.log(err);setFormMessage("Server error")})

  }else{
    console.log(formError);
  }

    //console.log(data);
  };


    return (
      <React.Fragment>
        <Header />
        <div className="login_container">
          <div className="login_card">
            <div className="pware">
              <p>Login Into Your Account</p>
            </div>

            <form className="login_form" onSubmit={handleSubmit}>
              <div>
                {" "}
                <input
                  type="email"
                  id="fname"
                  placeholder="Email or Phone Number..."
                  required="required"
                  onChange={(e)=>{setUsername(e.target.value)}}
                />
              </div>
              <div>
                <input
                  type="password"
                  id="pass"
                  name="pass"
                  placeholder="Password..."
                  required="required"
                  onChange={(e)=>{setPassword(e.target.value)}}
                />
              </div>

                <div>{formMessage}</div>

              <div className="forgot">
                <Link to="/Forgot">Forgotten Password ?</Link>
              </div>
              <button className="submit"> Login </button>
            </form>

            <div className="create">
              <p>
                {" "}
                Don't have an account? <br />{" "}
                <Link to="/signin" className="create_account">
                  Create account
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Foots />
      </React.Fragment>
    );
  }


export default Login;
