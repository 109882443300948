import React, { Component } from "react";

import { Avatar } from "@mui/material";
import little from "./images/little.jpg";
import { Link } from "react-router-dom";
import "./profile.css";
import {
  Add,
  CallOutlined,
  Language,
  Cake,
  ManageSearchOutlined,
  Email,
  Phone,
  LocationOn,
} from "@mui/icons-material";

class View extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="profile">
          <div className="view_pic_container">
            {" "}
            <div>
              <div className="view_pic">
                {" "}
                <p className="view_name_text">Chikelu Oyiyechukwu Elizabeth</p>
                <Avatar
                  src={little}
                  style={{ width: 85, height: 85, position: "revert" }}
                />{" "}
              </div>
            </div>
            <div className="view_balance">
              <div className="view_balance_text">
                {" "}
                <p>&#8358; </p>
                <p className="view_balance_amount"> 5,000</p>
              </div>
              <Link to="/Wallet">
                {" "}
                <div className="add">
                  {" "}
                  <Add style={{ marginBottom: 0 }} />{" "}
                </div>
              </Link>
            </div>
          </div>
          <div className="others">
            <div className="other_details">
              {" "}
              <p className="other_details_title">
                {" "}
                <Email /> Email:
              </p>{" "}
              <p className="other_details_content">
                Chikeluelizabeth@gmail.com
              </p>{" "}
            </div>
            <div className="other_details">
              {" "}
              <p className="other_details_title">
                {" "}
                <CallOutlined /> Phone Number:
              </p>{" "}
              <p className="other_details_content">09047571363</p>{" "}
            </div>
            <div className="other_details">
              {" "}
              <p className="other_details_title">
                <Cake />
                Date Of Birth:
              </p>{" "}
              <p className="other_details_content">27th July 19..</p>{" "}
            </div>
            <div className="other_details">
              {" "}
              <p className="other_details_title">
                <LocationOn /> Address
              </p>{" "}
              <p className="other_details_content">31, Enugu Road, Agbani</p>{" "}
            </div>
            <div className="other_details">
              {" "}
              <p className="other_details_title">
                {" "}
                <Language />
                Country:
              </p>{" "}
              <p className="other_details_content">Nigeria</p>{" "}
            </div>
          </div>
          <Link to="/Update">
            {" "}
            <button className="profile_submit">Edit Profile</button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default View;
