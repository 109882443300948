import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./main.css";
import Newlogo from "./images/logo.jpg";
import {
  CallOutlined,
  Login,
  PersonAdd,
  Menu,
  LocationOn,
  LogoutOutlined,
} from "@mui/icons-material";
class Header extends Component {
  state = {
    navbar: false,
    sub_navbar: true,
    click: true,
  };

  contact_click = () => {
    this.setState({ click: !this.state.click });
  };
  menu = () => {
    this.setState({ navbar: !this.state.navbar });
  };

  sub_menu = () => {
    this.setState({ sub_navbar: !this.state.sub_navbar });
  };

  authenticate = () => {};

  render() {
    let display = this.state.navbar ? "menu_display" : "";
    let display_two = this.state.sub_navbar ? "menu_show" : "";
    let click_drop = this.state.click ? "display" : "";
    return (
      <React.Fragment>
        <div className="header_container">
          <img className="mainlogo" src={Newlogo} />
          <div className="navbar1" onClick={this.menu}>
            <Menu style={{ fontSize: 28 }} />
          </div>
          <div className="menu_list">
            {" "}
            <ul className="menu_list_items">
              <li>Home</li> <li>Online Services</li>
              <li>About Us</li>
              <li>Contacts</li>
            </ul>
            <ul className="menu_list_items">
              <Link to="/login">
                {" "}
                <li className="extra_log">Login</li>
              </Link>
              <Link to="/Signin">
                {" "}
                <li className="extra_acc">Sign Up</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className={" phone_menu " + display}>
          <ul>
            <li>Home</li>
            <li>Online Services</li>
            <li>About</li>
            <li>Contacts</li>
            <Link to="/login">
              <li>Login</li>
            </Link>
            <Link to="/Signin">
              <li>Sign Up</li>
            </Link>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
