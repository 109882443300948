import React, { useState, useEffect } from "react";
import Foots from "./footer.jsx";
import "./forms.css";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { DriveFolderUpload, NoEncryption } from "@mui/icons-material";
import Sidebar from "./newsidebar.jsx";
import Services from "./form.jsx";
import axios from "axios";
import { margin } from "@mui/system";
import Cart from "./cartCom";
//import CartInfo from "./cartInfo.jsx";

//require('dotenv').config();

const Pserve = (props) => {
  const myHost = process.env.REACT_APP_SERVER_HOST || "http://localhost:5001";
  // let total= color + paper type + paper size + copies; form onsubmit;
  /*const amount = (event) => {
    let colour = event.target.name.color;
    alert(colour);
  };*/
  //other sizes function
  const [Otherform, setOtherform] = useState("others_hide");

  const [formMessage, setFormMessage] = useState();

  //get input values function
  const initialValues = {
    username: props.User,
    file: "",
    size: "",
    copies: 1,
    color: "",
    type: "",
    Describe: "",
    scale: "",
    length: "",
    width: "",
  };

  const sizeConverter = (Scale, Value) => {
    let inch = 0,
      mm = 0,
      cm = 0,
      m = 0,
      ft = 0;

    if (Scale === "Inch") {
      inch = Value;
      mm = (Value * 25.4).toFixed(2);
      m = (Value * 0.0254).toFixed(2);
      cm = (Value * 2.54).toFixed(2);
      ft = (Value / 12).toFixed(2);
    } else if (Scale === "Feet") {
      inch = (Value * 12).toFixed(2);
      mm = (Value * 304.8).toFixed(2);
      m = (Value * 0.3048).toFixed(2);
      cm = (Value * 30.48).toFixed(2);
      ft = Value;
    }

    return { inch: inch, mm: mm, cm: cm, m: m, ft: ft };
  };
  const [dWidth, setdWidth] = useState(sizeConverter("Feet", 0));
  const [dLenght, setdLenght] = useState(sizeConverter("Feet", 0));

  const [values, setValues] = useState({
    initialValues,
  });

  useEffect(() => {
    /*This set of code runs when the value of "values.scale, values.width, values.length" changes */
    setdWidth(sizeConverter(values.scale, values.width));
    setdLenght(sizeConverter(values.scale, values.length));
  }, [values.scale, values.width, values.length]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    //other size
    //let sizes = document.getElementById("sized").value;
    /*if (sizes === "extra") {
        setOtherform("others_show");
    } else {
      setOtherform("others_hide");
    }*/
  };

  useEffect(() => {
    if (values.size === "extra") {
      setOtherform("others_show");
    } else {
      setOtherform("others_hide");
    }
  }, [values.size]);

  //upload work for printing

  const click_upload = () => {
    document.getElementById("Attach").click();
  };

  const workUpload = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files[0].type.includes("tiff")) {
      setWork(<h1>{event.target.files[0].name}</h1>);
    } else if (event.target.files[0].type.includes("image")) {
      setWork(
        <img
          src={URL.createObjectURL(event.target.files[0])}
          alt=""
          className="p_img"
        />
      );
    } else if (event.target.files[0].type.includes("pdf")) {
      setWork(<h1>{event.target.files[0].name}</h1>);
    } else if (event.target.files[0].type.includes("x-zip-compressed")) {
      setWork(<h1>{event.target.files[0].name}</h1>);
    } else if (event.target.files[0].name.split(".").pop() === "cdr") {
      setWork(
        <span>
          <h1>{event.target.files[0].name}</h1>
          <p>Please make sure that all your texts are converted to cover</p>
        </span>
      );
    } else {
      setWork("File Formart not supported");
    }
    //console.log(work)
    setValues({
      ...values,
      file: event.target.files[0],
    });
  };
  const [work, setWork] = useState(false);

  let workPreview = work ? work : "File Preview";

  //To get the amount of a printing work

  const [Amount, setAmount] = useState("");
  var priceType;
  var totalsize;
  var Bamount;

  const amtfunc = () => {
    totalsize = dLenght.ft * dWidth.ft;

    //plain paper condition
    //A3
    if (
      values.Type === "Plain" &&
      values.size === "A3" &&
      values.color === "Monochrome"
    ) {
      priceType = 100;
    } else if (
      values.Type === "Plain" &&
      values.size === "A3" &&
      values.color === "Color"
    ) {
      priceType = 150;
    }
    //A4
    else if (
      values.Type === "Plain" &&
      values.size === "A4" &&
      values.color === "Monochrome"
    ) {
      priceType = 30;
    } else if (
      values.Type === "Plain" &&
      values.size === "A4" &&
      values.color === "Color"
    ) {
      priceType = 150;
    } //A5
    else if (
      values.Type === "Plain" &&
      values.size === "A5" &&
      values.color === "Monochrome"
    ) {
      priceType = 15;
    } else if (
      values.Type === "Plain" &&
      values.size === "A5" &&
      values.color === "Color"
    ) {
      priceType = 75;
    }
    //A6
    else if (
      values.Type === "Plain" &&
      values.size === "A6" &&
      values.color === "Monochrome"
    ) {
      priceType = 7.5;
    } else if (
      values.Type === "Plain" &&
      values.size === "A6" &&
      values.color === "Color"
    ) {
      priceType = 37.5;
    } //Glossy paper condition
    //A3
    else if (
      values.Type === "Glossy" &&
      values.size === "A3" &&
      values.color === "Monochrome"
    ) {
      priceType = 100;
    } else if (
      values.Type === "Glossy" &&
      values.size === "A3" &&
      values.color === "Color"
    ) {
      priceType = 150;
    }
    //A4
    else if (
      values.Type === "Glossy" &&
      values.size === "A4" &&
      values.color === "Monochrome"
    ) {
      priceType = 30;
    } else if (
      values.Type === "Glossy" &&
      values.size === "A4" &&
      values.color === "Color"
    ) {
      priceType = 150;
    } //A5
    else if (
      values.Type === "Glossy" &&
      values.size === "A5" &&
      values.color === "Monochrome"
    ) {
      priceType = 15;
    } else if (
      values.Type === "Glossy" &&
      values.size === "A5" &&
      values.color === "Color"
    ) {
      priceType = 75;
    }
    //A6
    else if (
      values.Type === "Glossy" &&
      values.size === "A6" &&
      values.color === "Monochrome"
    ) {
      priceType = 7.5;
    } else if (
      values.Type === "Glossy" &&
      values.size === "A6" &&
      values.color === "Color"
    ) {
      priceType = 37.5;
    }

    //Thick paper condition
    //A3
    else if (
      values.Type === "Thick" &&
      values.size === "A3" &&
      values.color === "Monochrome"
    ) {
      priceType = 150;
    } else if (
      values.Type === "Thick" &&
      values.size === "A3" &&
      values.color === "Color"
    ) {
      priceType = 170;
    }
    //A4
    else if (
      values.Type === "Thick" &&
      values.size === "A4" &&
      values.color === "Monochrome"
    ) {
      priceType = 150;
    } else if (
      values.Type === "Thick" &&
      values.size === "A4" &&
      values.color === "Color"
    ) {
      priceType = 170;
    } //A5
    else if (
      values.Type === "Thick" &&
      values.size === "A5" &&
      values.color === "Monochrome"
    ) {
      priceType = 75;
    } else if (
      values.Type === "Thick" &&
      values.size === "A5" &&
      values.color === "Color"
    ) {
      priceType = 85;
    }
    //A6
    else if (
      values.Type === "Thick" &&
      values.size === "A6" &&
      values.color === "Monochrome"
    ) {
      priceType = 37.5;
    } else if (
      values.Type === "Thick" &&
      values.size === "A6" &&
      values.color === "Color"
    ) {
      priceType = 42.5;
    }
    //Banner condition
    else if (
      values.Type === "Banner" &&
      totalsize > 25 /*&&
      values.scale === "Feet"*/
    ) {
      Bamount = 120;
      priceType = Bamount * totalsize;
    } else if (
      values.Type === "Banner" &&
      totalsize < 25 /*&&
      values.scale === "Feet"*/
    ) {
      Bamount = 130;
      priceType = Bamount * totalsize;
    }
    //SAV condition
    else {
      priceType = 150 * totalsize;
    }

    setAmount(priceType * values.copies);
  };

  const handleSubmit = async () => {
    setFormMessage("");
    //console.log(values);
    //console.log(Object.entries(values));
    /*const newVal = values.to .map((va)=>
    <li>{va}</li>
    )
    console.log(newVal);*/
    let emptyInput = false;
    const formData = new FormData();

    formData.append("username", initialValues.username);
    formData.append("orderType", "printing");
    Object.entries(values).forEach(([key, value]) => {
      if (key !== "initialValues") {
        if (value !== null) {
          alert(`${key} -> ${value}`);
          formData.append(key, value);
          //console.log(`${key}:${value}`)
        } else {
          emptyInput = true;
        }
      } else {
        //alert(`${key} -> ${value}`);
        //emptyInput = true;
      }
    });
    AddToCart();

    // console.log(formData);
    if (!emptyInput) {
      await axios
        .post(myHost + "/order/addToCart", formData)
        .then((res) => {
          console.log(res);
          //console.log(res.data.message)
          //getServices();
          setFormMessage(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          setFormMessage("Server error");
        });
      //console.log(formData);
    } else {
      setFormMessage("incomplet input");
    }
  };

  const [SubValue, setSubValue] = useState({
    exCopies: "",
    exColor: "",
    exType: "",
    Amt: "",
  });

  //get input values function

  const AddToCart = () => {
    setSubValue({
      exAmt: Amount,
      exCopies: values.copies,
      exColor: values.color,
      exType: values.Type,
    });
  };
  return (
    <React.Fragment>
      <div className="form_container">
        <p>Upload Document</p> <div className="img_preview">{workPreview}</div>
        <div className="import1">
          <input
            type="file"
            id="Attach"
            name="Attachment"
            onChange={workUpload}
          />
        </div>
        <button className="import" onClick={() => click_upload()}>
          Import
        </button>
      </div>
      <div className="upload_container">
        <div>
          <p>Paper Type</p>
          <select
            id="Type"
            name="Type"
            className="form_input"
            placeholder="select Type"
            required="required"
            onChange={handleInputChange}
          >
            <option value="" disabled selected hidden>
              Select type
            </option>{" "}
            <option value="Plain">Plain</option>{" "}
            <option value="Glossy">Glossy (i.e Poster, fliers)</option>{" "}
            <option value="Thick">Thick (Art Card)</option>
            <option value="SAV">SAV(Sticker)</option>
            <option value="Banner">Banner</option>
          </select>
        </div>
        <div>
          <p>Paper Size</p>
          <select
            id="sized"
            name="size"
            className="form_input"
            placeholder="select size"
            required="required"
            onChange={handleInputChange}
          >
            <option value="" disabled selected hidden>
              Select Size
            </option>
            <option value="A3">A3</option>
            <option value="A4">A4</option>
            <option value="A5">A5</option>
            <option value="A6">A6</option>
            <option value="extra">Others</option>
          </select>{" "}
          <div className={Otherform}>
            <h6 className="s_info">
              Kindly choose measurement scale and input size, i.e *inch 4 by 6*
            </h6>
            <div className="other_ft">
              <select
                id="others"
                className="form_input"
                required="required"
                name="scale"
                onChange={handleInputChange}
              >
                <option value="" disabled selected hidden>
                  Scale
                </option>{" "}
                <option value="Inch">Inch</option>
                <option value="Feet">Feet</option>
              </select>
              <input
                type="text"
                className="size_input"
                name="width"
                onChange={handleInputChange}
              />
              <pre>By</pre>
              <input
                type="text"
                className="size_input2"
                name="length"
                onChange={handleInputChange}
              />
            </div>

            <p>
              width : {dWidth.inch}inch; {dWidth.m}m; {dWidth.mm}mm;
              {dWidth.ft}
              ft; {dWidth.cm}cm;
            </p>
            <p>
              Lenght : {dLenght.inch}inch; {dLenght.m}m; {dLenght.mm}mm;
              {dLenght.ft}ft; {dLenght.cm}cm;
            </p>
          </div>
        </div>{" "}
        <p>
          Copies
          <input
            type="number"
            className="copy_input"
            required="required"
            value={values.copies}
            onChange={handleInputChange}
            name="copies"
          />
        </p>
        <div>
          <p>Color Mode</p>
          <select
            id="Color"
            name="color"
            className="form_input"
            placeholder="select Color"
            required="required"
            onChange={handleInputChange}
          >
            <option value="" disabled selected hidden>
              Select Color
            </option>
            <option value="Color">Color</option>
            <option value="Monochrome">Monochrome (Black and White)</option>
          </select>{" "}
        </div>
        <p>Description</p>
        <textarea
          type="text"
          id="describe"
          name="describe"
          className="describe_input"
          placeholder="*Optional*"
          onChange={handleInputChange}
        />
        <p>
          <input
            type="button"
            value="Get Amount"
            onClick={amtfunc}
            style={{ float: "none", margintop: 0 }}
            className="s_submit"
          />{" "}
          Estimated Amount: #{Amount}
        </p>
        <br />
        <div>{formMessage}</div>
        <input
          type="button"
          value="Add To Cart"
          onClick={handleSubmit}
          className="s_submit"
        />
        <div>{values.username}</div>
      </div>
    </React.Fragment>
  );
};

export default Pserve;
