import { DoneOutline, CheckCircleOutlined } from "@mui/icons-material";
import React, { Component } from "react";

class Confirm extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="confirmcont">
          <br />
          <div className="print_cont">
            <div className="tick">r</div>
            <div className="print_title">Payment Successful!</div>

            <div className="pay_details">
              <div>Payment Type</div>
              <div>Wallet</div>
            </div>

            <div className="pay_details">
              <div>Amount</div>
              <div>50</div>
            </div>

            <div className="pay_details">
              <div>Service</div>
              <div>Printing</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Confirm;
