import React, { Component } from "react";
import "./profile.css";
import Serve from "./images/online services.png";
import Info from "./images/info.png";
import Wall from "./images/wallet.png";
import { Link } from "react-router-dom";
import "./main.css";
import Edit from "./images/edit profile pic.jpg";
import Form from "./form.jsx";
import Me from "./images/mine.jpg";
//import popForm from "./popForm.css";

class Profile extends Component {
  state = {
    service: false,
    value: "",
    formShow: false,
    popup: false,
  };

  servicedisplay = () => {
    this.setState({ service: !this.state.service });
  };

  handleChange = (event) => {
    this.setState({ value: URL.createObjectURL(event.target.files[0]) });
  };

  formDisplay = () => {
    this.setState({ formShow: !this.state.formShow });
  };

  formPop = () => {
    this.setState({ popup: !this.state.popup });
  };

  render() {
    let show = this.state.service ? "menu_display" : "";
    let imgPreview = this.state.value ? (
      <img src={this.state.value} alt="" className="profile_img" />
    ) : (
      <img src={Me} alt="" className="profile_img" />
    );

    const Display = this.state.formShow;
    let phone_show = this.state.popup;
    return (
      <React.Fragment>
        <div className="row">
          <div className="profile_details">
            <div className="pic_contain">
              <div className="profile_pic">
                {imgPreview}

                <div className="edit_container">
                  <img className="edit_pic" src={Edit} alt="Edit" />
                  <form>
                    <input
                      type="file"
                      className="pic_input"
                      onChange={this.handleChange}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="wallet">
              <p>Chikelu Elizabeth</p>
              <p> Wallet balance:</p> <p></p>
            </div>
          </div>

          <div className="profile_service">
            <div className="profile_menu">
              <div className="each" onClick={this.formPop}>
                <img className="direct" src={Serve} /> <br />
                <p>Online Services</p>
              </div>
              <div className="each">
                <img className="direct" src={Wall} />
                <br /> <p>Add To Wallet</p>
              </div>
              <div className="each">
                <img className="direct" src={Info} />
                <br /> <p>Information</p>
              </div>
            </div>
          </div>

          <div className="profile_service2">
            <div className="profile_menu">
              <div className="each" onClick={this.formDisplay}>
                <img className="direct" src={Serve} /> <br />
                <p>Online Services</p>
              </div>
              <div className="each">
                <img className="direct" src={Wall} />
                <br /> <p>Add To Wallet</p>
              </div>
              <div className="each">
                <img className="direct" src={Info} />
                <br /> <p>Information</p>
              </div>
            </div>

            <div>{Display && <Form />}</div>
          </div>

          <div className="service_category">
            <ul className={" phone_menu " + show}>
              <li onClick={this.formPop} className="link">
                Assignment
              </li>
              <li>
                <Link to="/Form" className="link">
                  Printing
                </Link>
              </li>
              <li>
                <Link to="/Form" className="link">
                  Online Registrations
                </Link>
              </li>
              <li>
                <Link to="/Form" className="link">
                  Web Development
                </Link>
              </li>
              <li>
                <Link to="/Form" className="link">
                  Graphics
                </Link>
              </li>
              <li>
                <Link to="/Form" className="link">
                  Solar Services
                </Link>
              </li>
            </ul>
          </div>

          <div className="transaction">
            <p> Transaction history</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
