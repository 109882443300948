import React, { Component } from 'react';
import Login from './component/loginUser.jsx';
import Entry from './component/entry.jsx';
import Services from './component/form.jsx';
import Signin from './component/signUp.jsx';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Update from './component/profille_update.jsx'
import Profile from './component/user_profile.jsx';
//import Switch from "react-switch";
import Pay from './component/payment';
import View from './component/view_profile.jsx';
import Forgot from "./component/forgotPassword.jsx";
import Wallet from "./component/myWallet.jsx";
import Confirm from "./component/ConfirmationPage";
import Dashboard from "./component/dashboard.jsx";
import User from "./component/profile.jsx";
import Editp from "./component/editProfile.jsx";
import PageServices from "./component/pageServices.jsx";
import ManageUser from "./component/users.jsx";
import Cart from "./component/cartCom.jsx";
import Shop from "./component/shop.jsx";
import ManageRequest from "./component/requestView.jsx";
import ManageProducts from "./component/manageProducts.jsx"

function App() {
   return(
    <Router>
      <Routes>
      <Route exact path="/" element={<Entry/>}/>
      <Route  path="/login" element={<Login/>}/>
      <Route path="/signin" element={<Signin/>}/>
       <Route path="/Pay" element={<Pay/>}/>
      
      <Route  path="/Entry" element={<Entry/>}/>
      <Route  path="/Update" element={<Update/>}/>
      <Route  path="/View" element={<View/>}/>
      <Route  path="/Forgot" element={<Forgot/>}/>
      <Route  path="/Wallet" element={<Wallet/>}/>
      <Route  path="/Services" element={<Services/>}/>
      <Route  path="/Confirm" element={<Confirm/>}/>
      <Route  path="/Dashboard" element={<Dashboard/>}/>
      <Route  path="/User" element={<User/>}/>
      <Route  path="/Editp" element={<Editp/>}/>
      <Route  path="/pageServices" element={<PageServices/>}/>      
      <Route  path="/Cart" element={<Cart/>}/>
      <Route  path="/Shop" element={<Shop/>}/>
      <Route  path="/manageUsers" element={<ManageUser/>}/>
      <Route  path="/request" element={<ManageRequest/>}/>
      <Route  path="/manageProducts" element={<ManageProducts/>}/>
      </Routes>

    </Router>
  );

}

export default App;