import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../component/landing/css/landingpage.css";

const Fourth = () => {
  return (
    <React.Fragment>
      <div className="third_cont">
        <div className="to_align">
          {" "}
          <div className="plus_cont">
            <h1>Computer and Printer</h1>
            <h2 className="repair"> Repair</h2>
            <p>
              Repair of Laptop and desktops, Software installation and Upgrade
              and so much more...
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Fourth;
