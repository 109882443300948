import React, { useState, useEffect, useMemo} from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Foots from "./footer.jsx";
import "./forms.css";
import { Avatar } from "@mui/material";
import { DriveFolderUpload } from "@mui/icons-material";
import Sidebar from "./newsidebar.jsx";
import Pserve from "./pServices";
import Webserve from "./wservices";
import Gserve from "./gservices";

//require('dotenv').config();

const Services = (props) => {
  const [Showform, setShowform] = useState("Please select the service you need from us");

  const [user, setUser] = useState("");
  const [pwork, setPwork] = useState("");

  const [registered, setRegistered] = useState(true);

  
  const myHost = process.env.REACT_APP_SERVER_HOST||'http://localhost:5001';

  const navigate = useNavigate();
  

  const [siteUser, setSiteUser] = useState({});
  const [sideMenu, setSideMenu] = useState();
  
  useMemo(() => {
    const myHost = process.env.REACT_APP_SERVER_HOST||'http://localhost:5001';
    axios
      .post(myHost+"/user/isAuth")
      .then((res) => {
        console.log(res);
        setRegistered(res.data.isAuth);
        setUser(res.data.email);
        if(res.data.profile.image=== null){res.data.profile.image="default.jpg"}
        setSiteUser(res.data);
        setSideMenu(<Sidebar siteUser={res.data} />)
        
      })
      .catch((err) => console.log(err));
  
  }, []);
  useEffect(() => {
    if (!registered) {
      navigate("/login");
    }
  }, [registered]);

  const handleChange2 = (event) => {
    setUser(event.target.value);
  };

  const displayform = (event) => {
    setPwork(event.target.value);
  };
  useEffect(() => {
    if (pwork === "Prints") {
      setShowform(<Pserve User={user} returnFunc={returnService} />);
    } else if (pwork === "Graphics") {
      setShowform(<Gserve />);
    } else {
      setShowform(<Webserve />);
    }
  }, [pwork]);

  const returnService = () => {
    alert(user);
    return user;
  };

  // let total amount= color + paper type + paper size + copies; form onsubmit;
  // colour = #150
  /* 
    const [state, setState] = useState({
      colour_mode = event.target.value,    
      copies= event.target.value,
      paper_type= event.target.value,

    });

if ( colour_mode  === "color" ){

  }
  if ( paper_type  === "plain" ){

  }
    if (   copies === 1 ){

    }
      

  var amounts;
  <Pserve amounts={props.amount} />;
*/
  return (
    <React.Fragment>
      {sideMenu}
      <div className="dash_content2">
        <div className="title">Online Services</div>
        <div className="profile_first">
          <form>
            <div className="form_intro">
              <div className="intro_cont">
                <input
                  type="text"
                  id="Username"
                  name="Username"
                  placeholder="Username"
                  className="form_input"
                  required="required"
                  value={user}
                  onChange={handleChange2}
                />
                <select
                  id="show"
                  name="services"
                  className="form_input"
                  required="required"
                  onChange={displayform}
                >
                  <option value="" disabled selected hidden>
                    Service
                  </option>
                  <option value="Web">Web Development</option>
                  <option value="Prints">Printing</option>
                  <option value="Graphics">Graphics Design</option>
                </select>{" "}
              </div>
            </div>
            <div> {Showform}</div>
          </form>
        </div>
      </div>{" "}
    </React.Fragment>
  );
};

export default Services;
