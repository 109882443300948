import React, { useState, useEffect } from "react";
import "../main.css";
import Lheader from "./landhead.jsx";
import "./css/landingpage.css";
import Foots from "../footer.jsx";

const Landingpage = () => {
  return (
    <React.Fragment>
      <Lheader />

      <div className="soontext">AVALIABLE SOON...</div>
      <Foots />
    </React.Fragment>
  );
};

export default Landingpage;
