
import Second from "../assets/secondSlide";
import Third from "../assets/thirdSlide";
import Fourth from "../assets/fourthSlide";
import Pw from "./pware.jsx"

export default [
  {
    Comp: <Pw/>,
  },
  {
  Comp:<Second/>,
  },
  {
     Comp:<Third/>,
  },
  
{
     Comp:<Fourth/>,
  },
  
];
