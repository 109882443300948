import { Call } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Pw = () => {
  return (
    <React.Fragment>
      <div className="to_align">
        {" "}
        <div className="plus_cont">
          <p className="extra_wlc"> Welcome To</p> <h1>Pyramidware</h1>
          <p>Graphics Design, Printing, Website development, Repairs...</p>
          <div className="locate">
            <Call style={{ fontSize: 19, marginRight: 10 }} />{" "}
            <p> 07038621038; 09075765253</p>
          </div>
          <div className="entrance_btn">
            {" "}
            <Link to="/login">
              <div className="old_user dont">Login</div>
            </Link>
            <Link to="/Signin">
              <div className="new_user dont">Sign Up</div>
            </Link>
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pw;
